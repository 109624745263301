/*
 * UserIcon CSS Styles
*/

.usericon {
  display: inline-block;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  color: #111;
  cursor: default;
  box-shadow: 0 1px 4px #111;
  font-size: 20px;
  vertical-align: middle;
  position: relative;
  z-index: 1;
}

.usericon.small {
  width: 24px;
  height: 24px;
  line-height: 24px;
  font-size: 16px;
}
