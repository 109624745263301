/*
 * DaySelect CSS Styles
*/
.dayselect {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50px;
  padding: 8px;
  box-shadow: 0 4px 4px #181818;
}

.dayselect button {
  z-index: 1;
}

.dayselect input {
  background-color: #181818;
  color: #eee;
  border: none;
  line-height: 24px;
  margin-left: 16px;
  padding: 0 16px;
  text-align: center;
  font-size: 14px;
  z-index: 0;
}

.dayselect span {
  width: 96px;
  line-height: 24px;
  margin: 0 -4px;
  /*background-color: #2b2b2b;*/
  text-align: center;
  font-size: 14px;
  z-index: 0;
}

@media (max-width: 640px) {
  .dayselect {
    min-height: 48px;
  }
}
