/*
 * Online CSS Styles
*/
.ulist {
  display: flex;
  justify-content: center;
  min-height: 44px;
  padding: 8px 16px 4px;
  box-shadow: 0 4px 4px #181818;
  z-index: 2;
}
.ulist > span {
  margin: 0 8px;
}
@media (max-width: 640px) { 
  .ulist {
    min-height: 36px;
  }
  .name {
    display: none;
  }
  .ulist > span {
    margin: 0 4px;
  }
}

.off {opacity:0.18;}
