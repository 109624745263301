/*
 * Send CSS Styles
*/
@keyframes spinoffPulse {
    0% { transform:rotate(0deg); }
	100% { transform:rotate(360deg);  }
}

.messageSend {
  box-shadow: 0 -4px 4px #181818;
}

.messageSend form{
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.messageSend input {
  flex-grow: 3;
}

.messageSend .fileSend {
  margin-right: 4px;
  z-index: 10;
  position: relative;
}

.messageSend .fileSend.uploading:after {
  content: "";
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: -1px;
  right: -1px;
  border: 2px solid #ccc;
  border-radius: 50%;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
  animation: spinoffPulse 1s infinite linear;
}
