/*
 * SendButton CSS Styles
*/

.sendBtn {
  background: #8cf;
  color: #fff;
}

.sendBtn svg {
  position: relative;
  left: 1px;
}
