/*
 * Children CSS Styles
*/

.children {
  padding: 16px 0;
  font-size: 14px;
}
.children table {
  padding-bottom: 16px;
  width: 100%;
}
.children th {
  padding: 4px 16px;
  font-weight: 400;
}
.children th:first-child {
  text-align: left;
}
.children td {
  padding: 4px 16px;
  border-top: 1px solid #222;
  text-align: center;
}
.children td:first-child {
  text-align: left;
  font-size: 16px;
}
@media (max-width: 640px) { 
  .children {
    padding: 8px 0;
    font-size: 13px;
  }
  .children td:first-child {
    font-size: 14px;
  }
}
