/*
 * Intro CSS Styles
*/

.intro {
  padding: 0 0 32px;
}

.intro p {
  font-size: 17px;
  padding: 0 8px;
  text-align: center;
}

.intro div {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 16px 0;
}

.intro img {
  max-height: 250px;
  margin: 1px;
}

@media (max-width: 764px) { 
  .intro img {
    max-height: 192px;
  }
}
@media (max-width: 610px) { 
  .intro img {
    max-height: 152px;
  }
}
@media (max-width: 467px) { 
  .intro img {
    max-height: 115px;
  }
}
