/*
 * Login CSS Styles
*/

.loginInfo {
  margin: 32px 8px 16px;
  text-align: center;
}

.loginForm {
  width: 320px;
  margin: auto;
  padding: 8px;
}

.loginForm > div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 4px 0;
  cursor: pointer;
}

.loginForm input {
  margin-left: 8px;
  flex-grow: 3;
}
