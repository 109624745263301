/*
 * Chat CSS Styles
*/

.chat {
  display: flex;
  flex-direction: column;
}

.chat > * {
  z-index: 1;
}

div.messages {
  padding: 8px 0;
  font-size: 14px;
  line-height: 1.5;
  z-index: 0;
}
@media (max-width: 640px) { 
  div.messages {
    font-size: 13px;
  }
}
