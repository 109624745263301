/*
 * Writing CSS Styles
*/
@keyframes blink {
    0% {
      opacity: .2;
    }
    20% {
      opacity: 1;
    }
    100% {
      opacity: .2;
    }
}

.wait {
  padding-left: 10px;
}
.wait:last-child {
  padding-bottom: 16px;
}

.wait span{
  display: inline-block;
  font-size: 24px;
  line-height: 20px;
  animation-name: blink;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}

.wait.xl span{
  font-size: 48px;
  line-height: 48px;
}
.wait.xxl span{
  font-size: 72px;
  line-height: 72px;
}

.wait span:nth-child(2) {
  animation-delay: .2s;
}

.wait span:nth-child(3) {
  animation-delay: .4s;
}
