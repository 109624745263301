/*
 * Time CSS Styles
*/

.msgTime {
  display: inline-block;
  border-radius: 0 10px 10px 0;
  height: 20px;
  line-height: 20px;
  width: 64px;
  margin: 2px 8px 2px -8px;
  padding: 0 10px 0 0;
  text-align: right;
  background-color: #363738;
  color: #ddd;
  font-size: 12px;
  z-index: 0;
}
