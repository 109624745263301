@import url(https://fonts.googleapis.com/css?family=Fira+Sans&subset=latin-ext);
html, body, #root {
  height: 100%;
  max-height: 100%;
  scroll-behavior: smooth;
  font-family: 'Fira Sans', sans-serif;
}


body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  font-size: 14px;
  overflow: hidden;
  background: #181818;
}

body * {
  box-sizing: border-box;
  font-family: inherit;
  -webkit-tap-highlight-color: transparent;
}

:focus {
  outline: none;
}

svg {
  vertical-align: middle;
}

a, a:link, a:visited {
  color: inherit;
  -webkit-text-decoration-color: #fff3;
          text-decoration-color: #fff3;
}
a:hover {
  -webkit-text-decoration-color: inherit;
          text-decoration-color: inherit;
}

input[type="text"], input[type="password"] {
  border: 1px solid #2b2b2b;
  background: #262626;
  box-shadow: 0 1px 3px #00000040 inset;
  color: #eee;
  height: 28px;
  border-radius: 16px;
  padding: 6px 24px 6px 16px;
  line-height: 16px;
  position: relative;
  transition: all .3s linear;
}
input[type="text"]:focus, input[type="password"]:focus {
  border-color: #303336;
  background: #303132;
  z-index: 9;
}
input[type="text"].error, input[type="password"].error {
  border-color: #800;
  background: #2f2929;
}

button, .button, button:active {
  width: 34px;
  min-width: 34px;
  height: 34px;
  line-height: 34px;
  padding: 0;
  text-align: center;
  border-radius: 50%;
  border: none;
  box-shadow: 0 1px 4px #111;
  background: #555759;
  color: #eee;
  transition: all .2s linear;
}
button:hover, .button:hover {
  box-shadow: 0 1px 4px #000, 0 0 2px #000;
  color: #fff;
  cursor: pointer;
}
button:disabled {
  color: #444;
  background: #262626;
  box-shadow: 0 1px 2px #111;
  cursor: default;
}
button.error {
  background: #933;
}
button::-moz-focus-inner { 
  border: 0; 
}

input[type="text"] ~ button, input[type="password"] ~ button{
  margin-left: -20px;
  position: relative;
  z-index: 10;
}

/*
 * UserIcon CSS Styles
*/

.usericon {
  display: inline-block;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  color: #111;
  cursor: default;
  box-shadow: 0 1px 4px #111;
  font-size: 20px;
  vertical-align: middle;
  position: relative;
  z-index: 1;
}

.usericon.small {
  width: 24px;
  height: 24px;
  line-height: 24px;
  font-size: 16px;
}

/*
 * Badge CSS Styles
*/

.badge {
  display: inline-block;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  color: #eee;
  box-shadow: 0 1px 4px #000;
  vertical-align: middle;
  position: absolute;
}

.badge svg {
  transform: scale(.5);
  transform-origin: 7.5px 7.5px;
}

/*
 * Online CSS Styles
*/
.ulist {
  display: flex;
  justify-content: center;
  min-height: 44px;
  padding: 8px 16px 4px;
  box-shadow: 0 4px 4px #181818;
  z-index: 2;
}
.ulist > span {
  margin: 0 8px;
}
@media (max-width: 640px) { 
  .ulist {
    min-height: 36px;
  }
  .name {
    display: none;
  }
  .ulist > span {
    margin: 0 4px;
  }
}

.off {opacity:0.18;}

/*
 * Login CSS Styles
*/

.loginInfo {
  margin: 32px 8px 16px;
  text-align: center;
}

.loginForm {
  width: 320px;
  margin: auto;
  padding: 8px;
}

.loginForm > div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 4px 0;
  cursor: pointer;
}

.loginForm input {
  margin-left: 8px;
  flex-grow: 3;
}

/*
 * Navigation CSS Styles
*/

.navigation {
  background-color: #222324;
  display: flex;
  min-height: 54px;
  justify-content: center;
  box-shadow: 0 0 4px #111;
}

.navigation > div {
  flex: 1 1;
  min-width: 80px;
  max-width: 168px;
  padding: 8px 12px 4px;
  text-align: center;
  font-size: 10px;
  color: #888;
  cursor: pointer;
  transition: all .2s linear;
}

.navigation > div:hover {
  background: #303132;
  color: #eee;
}

.navigation > div.on {
  color: #fff;
}

.navigation > div > div {
  padding: 2px 0;
  max-width: 48px;
  margin: auto;
  position: relative;
}

/*
 * Intro CSS Styles
*/

.intro {
  padding: 0 0 32px;
}

.intro p {
  font-size: 17px;
  padding: 0 8px;
  text-align: center;
}

.intro div {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 16px 0;
}

.intro img {
  max-height: 250px;
  margin: 1px;
}

@media (max-width: 764px) { 
  .intro img {
    max-height: 192px;
  }
}
@media (max-width: 610px) { 
  .intro img {
    max-height: 152px;
  }
}
@media (max-width: 467px) { 
  .intro img {
    max-height: 115px;
  }
}

/*
 * DaySelect CSS Styles
*/
.dayselect {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50px;
  padding: 8px;
  box-shadow: 0 4px 4px #181818;
}

.dayselect button {
  z-index: 1;
}

.dayselect input {
  background-color: #181818;
  color: #eee;
  border: none;
  line-height: 24px;
  margin-left: 16px;
  padding: 0 16px;
  text-align: center;
  font-size: 14px;
  z-index: 0;
}

.dayselect span {
  width: 96px;
  line-height: 24px;
  margin: 0 -4px;
  /*background-color: #2b2b2b;*/
  text-align: center;
  font-size: 14px;
  z-index: 0;
}

@media (max-width: 640px) {
  .dayselect {
    min-height: 48px;
  }
}

/*
 * Time CSS Styles
*/

.msgTime {
  display: inline-block;
  border-radius: 0 10px 10px 0;
  height: 20px;
  line-height: 20px;
  width: 64px;
  margin: 2px 8px 2px -8px;
  padding: 0 10px 0 0;
  text-align: right;
  background-color: #363738;
  color: #ddd;
  font-size: 12px;
  z-index: 0;
}

/*
 * Message CSS Styles
*/

.message {
  display: flex;
  padding: 4px 8px;
}

.message:last-child {
  padding-bottom: 16px;
}

.message > * {
  flex-shrink:0;
}
.message > *:last-child {
  flex-shrink:5;
  margin: 2px 0;
}

.message .emoji {
  font-size: 20px;
  line-height: 21px;
}

.message .icon {
  height: 20px;
  vertical-align: -4px;
}

.message .icon.j {
  position: relative;
  margin: 0 4px;
  animation: .8s zehli infinite alternate;
}

.message .image {
  cursor: pointer;
  max-width: 250px;
  max-height: 250px;
}

@keyframes zehli {
  0% {
    left: 4px;
  }
  25% {
    left: 3px;
  }
  75% {
    left: -3px;
  }
  100% {
    left: -4px;
  }
}

/*
 * Writing CSS Styles
*/
@keyframes blink {
    0% {
      opacity: .2;
    }
    20% {
      opacity: 1;
    }
    100% {
      opacity: .2;
    }
}

.wait {
  padding-left: 10px;
}
.wait:last-child {
  padding-bottom: 16px;
}

.wait span{
  display: inline-block;
  font-size: 24px;
  line-height: 20px;
  animation-name: blink;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}

.wait.xl span{
  font-size: 48px;
  line-height: 48px;
}
.wait.xxl span{
  font-size: 72px;
  line-height: 72px;
}

.wait span:nth-child(2) {
  animation-delay: .2s;
}

.wait span:nth-child(3) {
  animation-delay: .4s;
}

/*
 * SendButton CSS Styles
*/

.sendBtn {
  background: #8cf;
  color: #fff;
}

.sendBtn svg {
  position: relative;
  left: 1px;
}

/*
 * Send CSS Styles
*/
@keyframes spinoffPulse {
    0% { transform:rotate(0deg); }
	100% { transform:rotate(360deg);  }
}

.messageSend {
  box-shadow: 0 -4px 4px #181818;
}

.messageSend form{
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.messageSend input {
  flex-grow: 3;
}

.messageSend .fileSend {
  margin-right: 4px;
  z-index: 10;
  position: relative;
}

.messageSend .fileSend.uploading:after {
  content: "";
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: -1px;
  right: -1px;
  border: 2px solid #ccc;
  border-radius: 50%;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
  animation: spinoffPulse 1s infinite linear;
}

/*
 * Preview CSS Styles
*/

.preview {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #181818;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0.3;
  visibility: hidden;
  z-index: -1 !important;
  cursor: pointer;
  transition: opacity .2s ease-out;
  -webkit-touch-callout: none;
}

.preview.on {
  visibility: visible;
  opacity: 1;
  z-index: 20 !important;
/*   transition-duration: .2s; */
}

.preview button {
  background: #55575955;
  position: fixed;
  top: 8px;
  right: 8px;
}
.preview button:hover {
  background: #555759ff;
}

.preview img {
  max-width: 100%;
  max-height: 100%;
}

.preview > div {
  transform: scale(.95);
  opacity: 0;
  transition: all .2s ease-out;
}
.preview.on > div {
  transform: scale(1);
  opacity: 1;
}


/*
 * Chat CSS Styles
*/

.chat {
  display: flex;
  flex-direction: column;
}

.chat > * {
  z-index: 1;
}

div.messages {
  padding: 8px 0;
  font-size: 14px;
  line-height: 1.5;
  z-index: 0;
}
@media (max-width: 640px) { 
  div.messages {
    font-size: 13px;
  }
}

/*
 * PlusSend CSS Styles
*/

.plusSend {
  box-shadow: 0 -4px 4px #181818;
  z-index: 2;
}

.plusSend form {
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.plusSend .pmswitch {
  position: relative;
  z-index: 10;
  margin-left: 4px;
}

.plusSend .uswitch {
  position: relative;
  z-index: 11;
}

.plusSend .uselect {
  position: absolute;
  z-index: 12;
  bottom: 40px;
  left: -2px;
/*   background: #181818cc; */
  
  padding: 2px;
  border-radius: 20px;
/*   box-shadow: 0 0 8px 2px #181818; */
}

.plusSend .uselect span {
  margin: 2px;
  cursor: pointer;
  box-shadow: 0 0 4px 2px #181818, 0 1px 5px 0 #181818;
}

.plusSend .uswitch button span {
  cursor: pointer;
}

.plusSend input {
  flex-grow: 3;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding-left: 24px;
  margin-left: -16px;
}

/*
 * Board CSS Styles
*/

.board {
  display: flex;
  flex-direction: column;
}

.board > * {
  z-index: 1;
}

.board .list {
  padding: 8px;
}

.board p {
  margin: 8px 0 8px 28px;
  text-indent: -28px;
  padding-right: 28px;
}
.board p:last-of-type {
  margin-bottom: 16px;
}
.board p > svg {
  margin: -4px 4px -4px 0;
}
.board .datum {
  display: inline-block;
  border-radius: 10px;
  height: 20px;
  line-height: 20px;
  /*width: 80px;*/
  margin: 0 4px;
  padding: 0 10px;
  text-align: center;
  text-indent: 0;
  background-color: #363738;
  color: #ddd;
  font-size: 12px;
  z-index: 0;
}

.board .komu {
  border-bottom: 1px solid #333;
  margin-bottom: 24px;
  text-indent: 0;
}
.board .komu > span {
  position: relative;
  top: 16px;
  left: 15%;
  box-shadow: 0 0 0 10px #181818;
}

@media (max-width: 640px) { 
  .board p {
    font-size: 13px;
  }
}

/*
 * TermSend CSS Styles
*/

.termSend {
  box-shadow: 0 -4px 4px #181818;
}

.termSend form {
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.termSend input:first-of-type {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  min-width: 80px;
}

.termSend input:last-of-type {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  flex-grow: 3;
  min-width: 220px;
  margin-left: -1px;
}

/*
 * Dictionary CSS Styles
*/

.dictionary {
  display: flex;
  flex-direction: column;
}

.dictionary > * {
  z-index: 1;
}

.dictionary dl {
  padding: 16px;
  margin: 0;
}

.dictionary dt {
  font-size: 16px;
  padding: 16px 0 4px;
  color: #fff;
}

.dictionary dd {
  font-size: 14px;
  padding-left: 24px;
  padding-bottom: 8px;
  color: #ccc;
}

@media (max-width: 640px) { 
  .dictionary dt {
    font-size: 14px;
  }
  .dictionary dd {
    font-size: 13px;
    padding-left: 16px;
  }
}

/*
 * Children CSS Styles
*/

.children {
  padding: 16px 0;
  font-size: 14px;
}
.children table {
  padding-bottom: 16px;
  width: 100%;
}
.children th {
  padding: 4px 16px;
  font-weight: 400;
}
.children th:first-child {
  text-align: left;
}
.children td {
  padding: 4px 16px;
  border-top: 1px solid #222;
  text-align: center;
}
.children td:first-child {
  text-align: left;
  font-size: 16px;
}
@media (max-width: 640px) { 
  .children {
    padding: 8px 0;
    font-size: 13px;
  }
  .children td:first-child {
    font-size: 14px;
  }
}

.App {
  height: 100%;
  min-height: 100%;
  color: #eee;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  min-height: 0;
}
.App .max {
  flex-grow: 4;
  overflow-y: auto;
  display: flex;
}
.App .scroll {
  flex-grow: 1;
  overflow-y: auto;
}

.cont {
  /*width: 100%;*/
  max-width: 960px;
  margin: auto;
}

@media (min-width: 960px) {
  .App .scroll > div {
    margin-left: calc(100vw - 100%);
  }
}

