/*
 * Preview CSS Styles
*/

.preview {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #181818;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0.3;
  visibility: hidden;
  z-index: -1 !important;
  cursor: pointer;
  transition: opacity .2s ease-out;
  -webkit-touch-callout: none;
}

.preview.on {
  visibility: visible;
  opacity: 1;
  z-index: 20 !important;
/*   transition-duration: .2s; */
}

.preview button {
  background: #55575955;
  position: fixed;
  top: 8px;
  right: 8px;
}
.preview button:hover {
  background: #555759ff;
}

.preview img {
  max-width: 100%;
  max-height: 100%;
}

.preview > div {
  transform: scale(.95);
  opacity: 0;
  transition: all .2s ease-out;
}
.preview.on > div {
  transform: scale(1);
  opacity: 1;
}

