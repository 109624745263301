/*
 * Message CSS Styles
*/

.message {
  display: flex;
  padding: 4px 8px;
}

.message:last-child {
  padding-bottom: 16px;
}

.message > * {
  flex-shrink:0;
}
.message > *:last-child {
  flex-shrink:5;
  margin: 2px 0;
}

.message .emoji {
  font-size: 20px;
  line-height: 21px;
}

.message .icon {
  height: 20px;
  vertical-align: -4px;
}

.message .icon.j {
  position: relative;
  margin: 0 4px;
  animation: .8s zehli infinite alternate;
}

.message .image {
  cursor: pointer;
  max-width: 250px;
  max-height: 250px;
}

@keyframes zehli {
  0% {
    left: 4px;
  }
  25% {
    left: 3px;
  }
  75% {
    left: -3px;
  }
  100% {
    left: -4px;
  }
}
