/*
 * TermSend CSS Styles
*/

.termSend {
  box-shadow: 0 -4px 4px #181818;
}

.termSend form {
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.termSend input:first-of-type {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  min-width: 80px;
}

.termSend input:last-of-type {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  flex-grow: 3;
  min-width: 220px;
  margin-left: -1px;
}
