/*
 * Board CSS Styles
*/

.board {
  display: flex;
  flex-direction: column;
}

.board > * {
  z-index: 1;
}

.board .list {
  padding: 8px;
}

.board p {
  margin: 8px 0 8px 28px;
  text-indent: -28px;
  padding-right: 28px;
}
.board p:last-of-type {
  margin-bottom: 16px;
}
.board p > svg {
  margin: -4px 4px -4px 0;
}
.board .datum {
  display: inline-block;
  border-radius: 10px;
  height: 20px;
  line-height: 20px;
  /*width: 80px;*/
  margin: 0 4px;
  padding: 0 10px;
  text-align: center;
  text-indent: 0;
  background-color: #363738;
  color: #ddd;
  font-size: 12px;
  z-index: 0;
}

.board .komu {
  border-bottom: 1px solid #333;
  margin-bottom: 24px;
  text-indent: 0;
}
.board .komu > span {
  position: relative;
  top: 16px;
  left: 15%;
  box-shadow: 0 0 0 10px #181818;
}

@media (max-width: 640px) { 
  .board p {
    font-size: 13px;
  }
}
