.App {
  height: 100%;
  min-height: 100%;
  color: #eee;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  min-height: 0;
}
.App .max {
  flex-grow: 4;
  overflow-y: auto;
  display: flex;
}
.App .scroll {
  flex-grow: 1;
  overflow-y: auto;
}

.cont {
  /*width: 100%;*/
  max-width: 960px;
  margin: auto;
}

@media (min-width: 960px) {
  .App .scroll > div {
    margin-left: calc(100vw - 100%);
  }
}
