/*
 * Navigation CSS Styles
*/

.navigation {
  background-color: #222324;
  display: flex;
  min-height: 54px;
  justify-content: center;
  box-shadow: 0 0 4px #111;
}

.navigation > div {
  flex: 1;
  min-width: 80px;
  max-width: 168px;
  padding: 8px 12px 4px;
  text-align: center;
  font-size: 10px;
  color: #888;
  cursor: pointer;
  transition: all .2s linear;
}

.navigation > div:hover {
  background: #303132;
  color: #eee;
}

.navigation > div.on {
  color: #fff;
}

.navigation > div > div {
  padding: 2px 0;
  max-width: 48px;
  margin: auto;
  position: relative;
}
