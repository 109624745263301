/*
 * Badge CSS Styles
*/

.badge {
  display: inline-block;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  color: #eee;
  box-shadow: 0 1px 4px #000;
  vertical-align: middle;
  position: absolute;
}

.badge svg {
  transform: scale(.5);
  transform-origin: 7.5px 7.5px;
}
