/*
 * PlusSend CSS Styles
*/

.plusSend {
  box-shadow: 0 -4px 4px #181818;
  z-index: 2;
}

.plusSend form {
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.plusSend .pmswitch {
  position: relative;
  z-index: 10;
  margin-left: 4px;
}

.plusSend .uswitch {
  position: relative;
  z-index: 11;
}

.plusSend .uselect {
  position: absolute;
  z-index: 12;
  bottom: 40px;
  left: -2px;
/*   background: #181818cc; */
  
  padding: 2px;
  border-radius: 20px;
/*   box-shadow: 0 0 8px 2px #181818; */
}

.plusSend .uselect span {
  margin: 2px;
  cursor: pointer;
  box-shadow: 0 0 4px 2px #181818, 0 1px 5px 0 #181818;
}

.plusSend .uswitch button span {
  cursor: pointer;
}

.plusSend input {
  flex-grow: 3;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding-left: 24px;
  margin-left: -16px;
}
