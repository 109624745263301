/*
 * Dictionary CSS Styles
*/

.dictionary {
  display: flex;
  flex-direction: column;
}

.dictionary > * {
  z-index: 1;
}

.dictionary dl {
  padding: 16px;
  margin: 0;
}

.dictionary dt {
  font-size: 16px;
  padding: 16px 0 4px;
  color: #fff;
}

.dictionary dd {
  font-size: 14px;
  padding-left: 24px;
  padding-bottom: 8px;
  color: #ccc;
}

@media (max-width: 640px) { 
  .dictionary dt {
    font-size: 14px;
  }
  .dictionary dd {
    font-size: 13px;
    padding-left: 16px;
  }
}
