@import url('https://fonts.googleapis.com/css?family=Fira+Sans&subset=latin-ext');

html, body, #root {
  height: 100%;
  max-height: 100%;
  scroll-behavior: smooth;
  font-family: 'Fira Sans', sans-serif;
}


body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  font-size: 14px;
  overflow: hidden;
  background: #181818;
}

body * {
  box-sizing: border-box;
  font-family: inherit;
  -webkit-tap-highlight-color: transparent;
}

:focus {
  outline: none;
}

svg {
  vertical-align: middle;
}

a, a:link, a:visited {
  color: inherit;
  text-decoration-color: #fff3;
}
a:hover {
  text-decoration-color: inherit;
}

input[type="text"], input[type="password"] {
  border: 1px solid #2b2b2b;
  background: #262626;
  box-shadow: 0 1px 3px #00000040 inset;
  color: #eee;
  height: 28px;
  border-radius: 16px;
  padding: 6px 24px 6px 16px;
  line-height: 16px;
  position: relative;
  transition: all .3s linear;
}
input[type="text"]:focus, input[type="password"]:focus {
  border-color: #303336;
  background: #303132;
  z-index: 9;
}
input[type="text"].error, input[type="password"].error {
  border-color: #800;
  background: #2f2929;
}

button, .button, button:active {
  width: 34px;
  min-width: 34px;
  height: 34px;
  line-height: 34px;
  padding: 0;
  text-align: center;
  border-radius: 50%;
  border: none;
  box-shadow: 0 1px 4px #111;
  background: #555759;
  color: #eee;
  transition: all .2s linear;
}
button:hover, .button:hover {
  box-shadow: 0 1px 4px #000, 0 0 2px #000;
  color: #fff;
  cursor: pointer;
}
button:disabled {
  color: #444;
  background: #262626;
  box-shadow: 0 1px 2px #111;
  cursor: default;
}
button.error {
  background: #933;
}
button::-moz-focus-inner { 
  border: 0; 
}

input[type="text"] ~ button, input[type="password"] ~ button{
  margin-left: -20px;
  position: relative;
  z-index: 10;
}
